import React from 'react'
import { v4 as uuidv4 } from 'uuid'
import { graphql, useStaticQuery } from 'gatsby'
import HeaderSEO from '../components/partials/HeaderSEO'
import Layout from '../components/Layout'
import { StaticImage } from 'gatsby-plugin-image'

const productSheets = () => {
  const data = useStaticQuery(graphql`
    query ProductSheetsQuery {
      allContentfulProductSheet {
        edges {
          node {
            slug
            title
            typeColor
            pdfType
          }
        }
      }
      allContentfulPages(
        filter: { pageReference: { pageIdentifier: { eq: "ProductSheets" } } }
      ) {
        edges {
          node {
            title {
              title
            }
            keywords {
              keywords
            }
            description {
              description
            }
            oGimage {
              fluid(quality: 100) {
                src
              }
            }
          }
        }
      }
    }
  `)

  const pageSEO = data.allContentfulPages.edges[0].node
  const productSheetsList = data.allContentfulProductSheet.edges

  return (
    <Layout>
      <HeaderSEO pageSEO={pageSEO} />
      <StaticImage
        className="home-fixed-top"
        src="../resources/images/homepage/hero_lens.svg"
        placeholder="blurred"
        alt="Hero Lenses"
      />
      <section className="pt-5 mt-5 pb-5">
        <div className="container ipad-margin-top">
          <div className="row mt-3">
            <div className="col-md-6 pt-0">
              <p className="title breadcrumb-text pt-4">
                <a href="/resources/" className="title_text">
                  RESOURCES
                </a>
              </p>
              <h1>Product Sheets</h1>
            </div>
          </div>
        </div>
      </section>

      <section id="summary" className="pt-5 pb-5">
        <div className="container">
          <div className="row">
            {productSheetsList.map(({ node }) => {
              return (
                <div className="col-sm-12 col-md-3 mb-4" key={uuidv4()}>
                  <div className="ds-card height-200 ipad-card">
                    <span
                      className={`ds-card-label-${node.typeColor} text-uppercase`}
                    >
                      {node.pdfType}
                    </span>
                    <h2 className="ds-card-title product-sheets-h2">
                      {node.title}
                    </h2>
                    <div className="text-center">
                      <div className="mt-3 d-flex justify-content-center">
                        <a
                          href={`/product-sheets/${node.slug}/`}
                          className="ds-card-button link-text"
                        >
                          DOWNLOAD
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
            {/* END OF FOR ABOVE ^ */}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default productSheets
